import React from 'react'
import { Link } from 'react-router-dom'
import bgone from '../../../assets/images/background/bg-16.png'
import bgtwo from '../../../assets/images/background/bg-17.png'
import bgthree from '../../../assets/images/background/bg-18.png'
import menuone from '../../../assets/images/resource/menus-1.jpg'
import specialities from '../../../assets/images/resource/oasis-specialities.jpg'
import menuimg15 from '../../../assets/images/resource/menu-image-15.jpg'
import menuimg16 from '../../../assets/images/resource/menu-image-16.jpg'
import chickenShawarmaPlatter from '../../../assets/images/resource/ChickenShawarmaPlatter.jpg'
import koftaKebab from '../../../assets/images/resource/koftaKebab.jpg'


import BeefPlatter from '../../../assets/images/resource/BeefPlatter.jpg'
import chickenPineaple from '../../../assets/images/resource/menu-image-12.jpg'
import shawarmaPineaplePl from '../../../assets/images/resource/shawarmaPineaplePl.jpg'
import MixedShawarmaPlatter from '../../../assets/images/resource/MixedShawarmaPlatter.jpg'
import royalFalafel from '../../../assets/images/resource/royalFalafel.jpg'
import ChickenSouvlaki from '../../../assets/images/resource/chickenSouvlaki.jpg'



function Startermenu() {
    return (
        <>
{/* Appetizers */}
            <section className="menu-one">
                {/* <div className="right-bg"><img src={bgone} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>STARTER</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Appetizers</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menuone} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Hummus <span className="s-info">VEGAN</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Creamy indulgence with a hint of tang, our hummus is a tantalizing dip that will leave you craving more.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Falafel <span className="s-info">VEGAN</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Royal Falafel made of chickpeas and spices. 6 Pieces comes in one plate.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Samosa <span className="s-info">VEGETARIAN</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Indian Snack samosa filled with potatoes and peas. Four samosa comes in one plate. It comes with Tamarind Sauce.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

{/* Main Platters */}
            <section className="menu-one">
                {/* <div className="right-bg"><img src={bgone} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>TOP SELLING</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Main Platters</h2>
                    </div>

                    <div className="row clearfix">
                        
                    <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image"><Link to="#"><img src={chickenShawarmaPlatter} alt="" /></Link></div>
                                <h4><Link to="#">Chicken Shawarma Platter</Link></h4>
                                <div className="text desc">Platter comes Salad of your choice, three spreads, Rice , chicken, garlic potatoes and three sauces</div>
                                <div className="price"> </div>
                            </div>
                        </div>

                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                                <div className="image"><Link to="#"><img src={shawarmaPineaplePl} alt="" /></Link></div>
                                <h4><Link to="#">Chicken Shawarma Pineaple Platter</Link></h4>
                                <div className="text desc">Platter comes Salad of your choice, three spreads, Rice , 
                                Chicken with Pineapple, garlic potatoes and three sauces .</div>
                                <div className="price"> </div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <div className="image"><Link to="#"><img src={BeefPlatter} alt="" /></Link></div>
                                <h4><Link to="#">Beef Shawarma Platter</Link></h4>
                                <div className="text desc">Platter comes Salad of your choice, three spreads, 
                                Rice ,Beef, garlic potatoes and three sauces.</div>
                                <div className="price"> </div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div className="image"><Link to="#"><img src={MixedShawarmaPlatter} alt="" /></Link></div>
                                <h4><Link to="#">Mixed Shawarma Platter</Link></h4>
                                <div className="text desc">Platter comes Salad of your choice, three spreads, 
                                Rice , Chicken and Beef mixed, garlic potatoes and three sauces.</div>
                                <div className="price"> </div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image"><Link to="#"><img src={ChickenSouvlaki} alt="" /></Link></div>
                                <h4><Link to="#">Chicken Souvlaki Platter</Link></h4>
                                <div className="text desc">Platter comes Salad of your choice, three spreads, 
                                Rice, chicken souvlaki, garlic potatoes and three sauces.</div>
                                <div className="price"> </div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                                <div className="image"><Link to="#"><img src={koftaKebab} alt="" /></Link></div>
                                <h4><Link to="#">Kofta Kabob Platter</Link></h4>
                                <div className="text desc">Platter comes Salad of your choice, three spreads, Rice , 
                                grounded beef kabob, garlic potatoes and three sauces.</div>
                                <div className="price"> </div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <div className="image"><Link to="#"><img src={royalFalafel} alt="" /></Link></div>
                                <h4><Link to="#">Roayl Falafel Platter</Link></h4>
                                <div className="text desc">Platter comes Salad of your choice, three spreads, 
                                Rice , Falafel, garlic potatoes and three sauces.</div>
                                <div className="price"> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

{/* Specials */}
            <section className="menu-one alternate">
                {/* <div className="left-bg"><img src={bgtwo} alt="" title="" /></div>
                <div className="right-bg-2"><img src={bgthree} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Specials</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Our Specialities</h2>

                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={specialities} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Oasis on The Rocks<span className="s-info"> </span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Regular Size Basmati rice with any meat (chicken, beef, mixed or Falafel) with three sauces.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Oasis on the Ice<span className="s-info"> </span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Regular Size Salad with any meat (Chicken, Beef, mixed or Falafel) with three sauces</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Oasis on the Sticks <span className="s-info"> </span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Regular Fries with any meat (chicken, beef or mixed) or Falafel with three sauces.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Oasis Triple Delight <span className="s-info"> </span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Regular Size first layer rice, then fries and on top Salad with any meat (chicken, beef or mixed) or Falafel with three sauces.</Link></div>
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Startermenu