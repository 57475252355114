import React from 'react';
import footerBg from '../../assets/images/background/footerBg.png';
import { Link } from 'react-router-dom'
import logonew from '../../assets/images/Logo-inv.svg'


function Main() {
    return (
        <>

            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${footerBg})` }}> </div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">

{/* Contact , Location , Opening Hours */}
                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo"><Link to="/" title="Oasis Shawarma and Wings"><img src={logonew} alt="" title="Oasis Shawarma and Wings" /></Link></div>
                                        <div className="info">
                                            <ul>
                                                <li>3308 LAKE SHORE BLVD W, UNIT 5, ETOBICOKE, ON, M8V 1M4</li>
                                                <li><Link to="tel:+88-123-123456">Booking Request : +1 647-368-8200</Link></li>
                                                <li>Tuesday - Sunday : 10:30 - 12:00</li>
                                                <li>Monday: Closed</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
{/* Pages */}
                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="/">Home</Link></li>
                                        <li> <Link to="/menu">Menus</Link></li>
                                        <li> <Link to="/about">About us</Link></li>
                                        <li> <Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
{/* Social Media Links */}
                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <a href="https://www.facebook.com">facebook</a></li>
                                        <li> <a href="https://www.instagram.com/oasis_shawarma/">instagram</a></li>
                                        <li> <a href="https://twitter.com">Twitter</a></li>
                                        <li> <a href="https://www.youtube.com">Youtube</a></li>
                                        <li> <a href="https://goo.gl/maps/K4fTmaiyTDrrFcBX6">Google map</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
{/* copyrights */}
                {/* <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2022 Restaurt. All Rights Reserved   |    Crafted by <Link to="https://themeforest.net/user/kalanidhithemes" target="blank">Kalanidhi Themes</Link></div>
                    </div>
                </div> */}

            </footer>

            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>
            
        </>

    )
}

export default Main