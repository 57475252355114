import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import bgone from '../../assets/images/background/bg-5.png'
import Bgtwo from '../../assets/images/background/bg-6.png'
import five from '../../assets/images/resource/menu-image-5.png'
import six from '../../assets/images/resource/menu-image-6.png'
import seven from '../../assets/images/resource/menu-image-7.png'
import eight from '../../assets/images/resource/menu-image-8.png'
import nine from '../../assets/images/resource/menu-image-9.png'
import ten from '../../assets/images/resource/menu-image-10.png'


function Menusection() {
    // const [tabMenu, tabActive] = useState({ a: true })

    return (
        <>
            <section className="menu-section">

            </section>
        </>
    )
}

export default Menusection