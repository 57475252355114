import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';
import chickenShawarmaPlatter from '../../assets/images/resource/ChickenShawarmaPlatter.jpg'
import beefShawarmaPlatter from '../../assets/images/resource/BeefPlatter.jpg'
import MixedShawarmaPlatter from '../../assets/images/resource/MixedShawarmaPlatter.jpg'
import royalFalafel from '../../assets/images/resource/royalFalafel.jpg'


function Special() {
    const options = {
        items: 4,
        rewind: false,
        loop: false,
        margin: 45,
        nav: true,
        smartSpeed: 700,
        autoplay: false,
        autoplayTimeout: 7000,
        navText: ['<span class="icon fa-light fa-angle-left"></span>', '<span class="icon fa-light fa-angle-right"></span>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 2,
                margin: 30
            },
            992: {
                items: 3,
                margin: 30
            },
            1200: {
                items: 4
            }
        }
    };

    return (

        <>
            <section className="special-offer">
                <div className="outer-container">

                    <div className="auto-container">

                        <div className="title-box centered">
                            <div className="subtitle"><span> Specialties </span></div>

                            <div className="pattern-image">
                                <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                            </div>

                            <h2> Main Platters </h2>
                        </div>

                        <OwlCarousel className="dish-gallery-slider owl-carousel owl-theme" options={options}>

                            <div className="offer-block-two">
                                <div className="inner-box">
                                    <div className="image"><Link to="/"><img src={chickenShawarmaPlatter} alt="" /></Link></div>
                                    <h4><Link to="#">Chicken Shawarma Platter</Link></h4>
                                    <div className="text desc">Platter comes Salad of your choice, three spreads, Rice , chicken, garlic potatoes and three sauces</div>
                                    <div className="price"> </div>
                                </div>
                            </div>

                            <div className="offer-block-two margin-top">
                                <div className="inner-box">
                                    <div className="image"><Link to="/"><img src={beefShawarmaPlatter} alt="" /></Link></div>
                                    <h4><Link to="#">Beef Shawarma Platter</Link></h4>
                                    <div className="text desc">Platter comes Salad of your choice, three spreads, 
                                Rice ,Beef, garlic potatoes and three sauces.</div>
                                    <div className="price"> </div>
                                </div>
                            </div>

                            <div className="offer-block-two">
                                <div className="inner-box">
                                    <div className="image"><Link to="/"><img src={MixedShawarmaPlatter} alt="" /></Link></div>
                                    <h4><Link to="#">Mixed Shawarma Platter</Link></h4>
                                    <div className="text desc">Platter comes Salad of your choice, three spreads, 
                                Rice, chicken souvlaki, garlic potatoes and three sauces.</div>
                                    <div className="price"> </div>
                                </div>
                            </div>

                            <div className="offer-block-two margin-top">
                                <div className="inner-box">
                                    <div className="image"><Link to="/"><img src={royalFalafel} alt="" /></Link></div>
                                    <h4><Link to="#">Royal Falafel Platter</Link></h4>
                                    <div className="text desc">Platter comes Salad of your choice, three spreads, 
                                Rice , Chicken and Beef mixed, garlic potatoes and three sauces.</div>
                                    <div className="price"> </div>
                                </div>
                            </div>
                        </OwlCarousel>

                        <div className="lower-link-box text-center">
                            <Link to="/menu" className="theme-btn btn-style-two clearfix">
                                <span className="btn-wrap">
                                    <span  className="text-one">view all menu</span>
                                    <span className="text-two">view all menu</span>
                                </span>
                            </Link>
                        </div>

                    </div>

                </div>
            </section>
        </>

    )
}

export default Special