import React from 'react'
import { Link } from 'react-router-dom'
import bg3 from '../../assets/images/background/bg-3.png'
import badge1 from '../../assets/images/resource/badge-1.png'
import img2 from '../../assets/images/resource/Oasis Sticker.png'
import everyFlavor from '../../assets/images/resource/everyflavor.jpg'

function Story() {
    return (

        <>

            <section className="story-section">

                {/* <div className="left-bg"><img src={bg3} alt="" title="" /></div> */}

                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-xl-5 col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="title-box centered">
                                    <div className="subtitle"><span> Our story </span></div>
                                    <div className="pattern-image">

                                        <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                    </div>
                                    <h2>Every Flavor Tells a Story</h2>
                                    <div className="text">
                                    Welcome to Oasis Shawarma and Wings, where we pride ourselves on serving delicious, 
                                    high-quality food that satisfies the taste buds and nourishes the body. Our menu features a 
                                    variety of dishes made with fresh, locally sourced ingredients, cooked to perfection by our skilled chefs.
                                    </div>
                                </div>
                                <div className="booking-info">
                                    <div className="bk-title">Book Through Call</div>
                                    <div className="bk-no"><Link href="tel:+80-400-123456">+1 (647)-368-8200</Link></div>

                                </div>
                            </div>
                        </div>

                        <div className="image-col col-xl-7 col-lg-7 col-md-12 col-sm-12">
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                {/*Badge rotating*/}
                                {/* <div className="round-stamp"><img src={badge1} alt="" /></div> */}
                                <div className="images parallax-scene-1">
                                    <div className="image" data-depth="0.15"><img src={everyFlavor} alt="" /></div>

                                    {/* Stamp Image */}
                                    <div className="image" data-depth="0.30"><img src={img2} alt="" /></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

        </>

    )
}

export default Story