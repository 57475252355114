import React, { useState, useEffect } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom'
import logo from '../../assets/images/resource/sidebar-logo.png'
import logonew from '../../assets/images/logo.svg'
import imgone from '../../assets/images/resource/menu-image-1.jpg'
import imgtwo from '../../assets/images/resource/menu-image-2.jpg'
import Imgthree from '../../assets/images/resource/menu-image-3.jpg'
import Imgfour from '../../assets/images/resource/menu-image-4.jpg'


function Main() {




    

    const [active, setActive] = useState();
    const [show, setShow] = useState();
    const [menu, setMenu] = useState();
    const [page, setPage] = useState();
    const [drop, setDrop] = useState();
    const [lastscrool, setlastscrool] = useState(0);

    const [scrolled,setScrolled]=useState(false);
    const [Nav,setNav]=useState(true);
    
    const handleScroll=() => {
        
        const offset=window.scrollY;
        setlastscrool(offset);
        
        if(offset > 1000 && offset < lastscrool){
            setNav(true);
            setScrolled(true);
        }

        else if(offset > 1000 ){
          setNav(false)
        }

        else if(offset > 200){
            setNav(true);
            setScrolled(true);
        }
        
        else{
          setScrolled(false);
          setNav(true);
        }
      }
    
      useEffect(() => {
        window.addEventListener('scroll',handleScroll)
      })

      
    return (

        <>

        {active && 
            <div className="menu-backdrop" style={{ opacity: "1"  , visibility: "visible"}} onClick={() => setActive(false)}></div> }

                <section className={`hidden-bar ${active && "visible-sidebar"}`} onClick={() => setActive(false)} >
                    <div className="inner-box">
                        <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)} ><span className="far fa-close"> </span></div>
                        <div className="logo-box"><Link to="/" title="Oasis Shawarma and Wings"><img src={logo} alt="" title="Oasis Shawarma and Wings" />
                        </Link></div>
                    </div>
                </section>
            {Nav && 
                <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                        <li><i className="icon far fa-map-marker-alt"></i> 3308 LAKE SHORE BLVD W, UNIT 5, ETOBICOKE M8V 1M4, ON</li>
                                        <li><i className="icon far fa-clock"></i> Tuesday - Sunday : 10.30 am to 12.00 pm   -  Monday : Closed</li>
                                    </ul>
                                </div>
                                <div className="top-right clearfix">
                                    <ul className="top-info clearfix">
                                        <li><Link><i className="icon far fa-phone"></i> +1 (647) 368 8200 </Link> </li>
                                        <li><Link><i className="icon far fa-envelope"></i> oasisshawarmaandwings@gmail.com</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-upper header-fixed" >
                        <div className="auto-container">

                            <div className="main-box d-flex justify-content-between align-items-center flex-wrap">
                                <div className="logo-box">
                                    <div className="logo"><Link to="/" title="Oasis Shawarma and Wings"><img src={logonew} alt="" title="Oasis Shawarma and Wings" /></Link></div>
                                </div>

                                <div className="nav-box clearfix">

                                    <div className="nav-outer clearfix">
                                        <nav className="main-menu">
                                            <ul className="navigation clearfix">
                                                <li className="current dropdown"><Link to="/">Home</Link>
                                                    <ul>
                                                        <li> <Link to="/hometwo">Home 1 Left Header</Link></li>
                                                        <li> <Link to="/">Home 1 Center Header</Link></li>
                                                        <li> <Link to="/homethree">Home 3 Video Hero</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown has-mega-menu"><Link to="/menu">Menus</Link>
                                                    <ul>
                                                        <li>
                                                            <div className="mega-menu">
                                                                <div className="menu-inner">
                                                                    <div className="auto-container">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/about">About Us</Link></li>
                                                <li><Link to="/ourchefs">Our chefs</Link></li>
                                                <li className="dropdown"><Link to="/">Pages</Link>
                                                    <ul>
                                                        <li> <Link to="#">Dropdown Menu 1</Link></li>
                                                        <li> <Link to="#">Dropdown Menu 2</Link></li>
                                                        <li> <Link to="#">Dropdown Menu 3</Link></li>
                                                        <li className="dropdown"><Link to="#">Dropdown Menu 4</Link>
                                                            <ul>
                                                                <li> <Link to="#">Dropdown Menu level 2</Link></li>
                                                                <li> <Link to="#">Dropdown Menu level 2</Link></li>
                                                                <li> <Link to="#">Dropdown Menu Level 3</Link></li>
                                                            </ul>
                                                        </li>
                                                        <li><Link to="#">Dropdown Lorem 5</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="#">Blog</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                            </ul>
                                        </nav>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </header> }
            
        </>

    )
}

export default Main