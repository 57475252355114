import React, {  useState, useEffect} from 'react'
import { Route, Routes , useLocation} from 'react-router-dom';
import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import About from '../components/About/Main';
import Contact from '../components/Contact/Main';
import Menu from '../components/Menu/Main';
import Book from '../components/Book/Main';
// import Scrool from '../components/Menhome/Scrool';
import Hometwo from '../components/Headertwo/Main'



function Index() {

  const [homepage, sethomepage] = useState(false)
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/"){
        sethomepage(false)
      }else{
        sethomepage(true)
      }
    }, [location])

  // const location = useLocation()
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
}, [path]);

  return (

    <>
     
      {homepage && <Header/>}

      <Routes>
        
        <Route path="/" element={<Hometwo />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reservation" element={<Book />} />
      </Routes>

      <Footer />
    </>

  )
}

export default Index