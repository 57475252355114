import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/background/bgReview.jpg'
import qrCode from '../../assets/images/SVG/QR.svg'
import OwlCarousel from 'react-owl-carousel';
import author1 from '../../assets/images/resource/author-thumb-1.jpg'
import author2 from '../../assets/images/resource/author-thumb-2.jpg'
import author3 from '../../assets/images/resource/author-thumb-3.jpg'


function Contact() {
    const [flag, setflag] = useState(false)
    const slider1 = useRef()
    const slider2 = useRef()
    const duration = 500;

    const syncPosition = (e) => {
        if (!flag) {
            setflag(false)
            if (slider2.current) {
                slider2.current.to(e.item.index, duration)
            }
            setflag(false)
        }
    }
    

    return (
        <>
        {/* Reviews */}
            <div className="testimonials-section" >
                <div className="image-layer" style={{ backgroundImage: `url(${Bgtwo})` }}></div>
                <div className="auto-container">
                    
                    <h3 className="text quote-text">Don't just take our word for it - check out our customer reviews to see what people are saying about us! 
                        We're proud to have received many positive reviews for our food, service, and atmosphere.
                    </h3>

                    <div className="carousel-box owl-theme">
                        <OwlCarousel className="testi-top owl-theme owl-carousel" ref={slider1} items={1} loop margin={10} nav autoplay onChange={(e) => syncPosition(e)}>
                            <div className="slide-item item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">I wanted to thank you for inviting me down for that 
                                    amazing dinner the other night. The food was extraordinary. </div>
                                    <div className="text quote-text">"John Smith"</div>
                                </div>
                            </div>
                            <div className="slide-item item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">I wanted to thank you for inviting me down for that 
                                    amazing dinner the other night. The food was extraordinary. </div>
                                    <div className="text quote-text">"Jane Doe"</div>
                                </div>
                            </div>
                            <div className="slide-item item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">I wanted to thank you for inviting me down for that 
                                    amazing dinner the other night. The food was extraordinary. </div>
                                    <div className="text quote-text">"John Doe"</div>
                                </div>
                            </div>

                        </OwlCarousel>
                        <div className="separator"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>


        {/* Contact us / Booking */}
            <section className="reserve-section">
                <div className="auto-container">
                    <div className="outer-box">

                        <div className="row clearfix">
                            <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        {/* QR Code */}
                                        <div className="request-info">Booking request <Link to="#">+1 647-368-8200</Link> or scan the QR Code below to order online</div>
                                        <div className="qrCode"><Link target='_blank' to='https://www.clover.com/online-ordering/oasis-shawarma-and-win-toronto?utm_source=web-dashboard&utm_medium=qrcode&utm_campaign=olo-qr-template'><img src={qrCode}></img></Link></div>
                                    </div>
                                </div>
                            </div>
                            <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>Contact Us</h2>
                                    </div>
                                    <div className="data">
                                        <div className="booking-info">
                                            <div className="bk-title">Booking request</div>
                                            <div className="bk-no"><Link to="tel:+88-123-123456">+1647-368-8200</Link></div>
                                            
                                        </div>
                                        <div className="separator"><span></span></div>
                                        <ul className="info">
                                            <li><strong>Location</strong><br />3308 Lake Shore Blvd W unit5, Etobicoke, ON M8V 1M4</li>
                                            <li><strong>Opening Times</strong><br></br>
                                            Sunday	10:30 a.m.–12 a.m.
                                            Monday (Victoria Day)	Closed
                                            Tuesday	10:30 a.m.–12 a.m.
                                            Wednesday	10:30 a.m.–12 a.m.
                                            Thursday	10:30 a.m.–12 a.m.
                                            Friday	10:30 a.m.–1 a.m.
                                            Saturday	10:30 a.m.–1 a.m.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact