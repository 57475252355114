import React from 'react'
import { Link } from 'react-router-dom'
import Iframe from 'react-iframe'
import bg25 from '../../assets/images/background/bg-25.png'
import bg6 from '../../assets/images/background/bg-6.png'
import restro from '../../assets/images/SVG/QR.svg'






function Contactbanner() {
    return (


        <>

            <div className="contact-map">
                <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.464354879681!2d-79.52629498450406!3d43.596872279123325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b49afbb977cbf%3A0x1eaa850822775e9d!2sOasis%20Shawarma%20and%20Wings!5e0!3m2!1sen!2sca!4v1684283398255!5m2!1sen!2sca" width="100%" height="500" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe>
            </div>
            <section className="contact-page">
            <Link to='https://www.clover.com/online-ordering/oasis-shawarma-and-win-toronto?utm_source=web-dashboard&utm_medium=qrcode&utm_campaign=olo-qr-template' target='_blank'>
                <div className="left-bg">
                        <img src={bg25} alt="" title="" />
                </div>
                </Link>

                <div className="location-center">
                    <div className="auto-container">
                        <div className="cinfo-box">
                            <div className="row clearfix">

                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Opening Time:</h4>
                                        <div className="text"> Tuesday - Sunday <br />  10.30 am to 12.00 pm</div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box cp-seprator wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Contact Info</h4>
                                        <div className="text">3308 Lake Shore Blvd W unit5, Etobicoke, ON M8V 1M4<br />Email : oasisshawarmaandwings@gmail.com</div>
                                        <div className="more-link"><Link to="#">Booking : +1 647-368-8200</Link></div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                        <h4>Closed</h4>
                                        <div className="text">Monday<br /></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="auto-container">
                    <div className="c-page-form-box">
                        <div className="row clearfix">

                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <div className="title-box centered">
                                    <h2>Message us</h2>
                                    <div className="text desc">Have a question about the our service? We're here to help, contact us today</div>
                                    <div className="text desc">Or Scan the Code Below to order online</div>
                                    
                                </div>
                                <div className="default-form reservation-form">
                                    <form method="post" action="index.html">
                                        <div className="clearfix">


                                            <div className="form-group">

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {/* QR to be included here */}
                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                 <img src={restro} alt="QR" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contactbanner