import React from 'react'
import { Link } from 'react-router-dom'
import bg19 from '../../../assets/images/background/bg-19.png'
import bg20 from '../../../assets/images/background/bg-20.png'
import menuimg13 from '../../../assets/images/resource/menu-image-13.jpg'
import menuimg14 from '../../../assets/images/resource/menu-image-14.jpg'
import menuimg15 from '../../../assets/images/resource/menu-image-15.jpg'
import menuimg16 from '../../../assets/images/resource/menu-image-16.jpg'
import menuimg17 from '../../../assets/images/resource/menu-image-17.jpg'
import menuimg19 from '../../../assets/images/resource/menu-image-19.jpg'
import chickenPineaple from '../../../assets/images/resource/menu-image-12.jpg'
import menuone from '../../../assets/images/resource/Shawarma Wrap.jpg'



function Specialmenu() {
    return (

        <>     
            <section className="special-offer-two">
                {/* <div className="left-bg"><img src={bg20} alt="" title="" /></div>
                <div className="right-bg"><img src={bg19} alt="" title="" /></div> */}

            </section>


            {/* Wraps and Combos */}
            <section className="menu-one">
                {/* <div className="right-bg"><img src={bgone} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>MAIN</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Wraps and Combos</h2>
                        <h5>Make it a Combos by adding Fries and Pop</h5>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menuone} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Chicken Shawarma</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Chicken Shawarma Wrap combo comes with fries or Garden Salad and Pop of your choice</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Chicken Pineaple</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Grilled chicken infused with tropical pineapple flavors, wrapped in a fluffy embrace for a refreshing and delightful taste.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Beef Shawarma</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Tender Gyro Sliced Beef, slow-roasted and wrapped in soft flatbread, delivering an irresistible taste of the Middle East.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Kofta Kabob</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Savory ground beef or lamb, grilled to perfection, nestled in a pita with fresh veggies and aromatic sauce, for a flavorful delight.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Mixed Shawarma</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">A delectable combination of seasoned beef and Juicy marinated chicken, wrapped in warm pita bread, creating a savory blend that satisfies every craving.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Chicken Souvlaki</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Grilled Chicken breast, crisp veggies, and Tahini sauce, wrapped in a soft pita for a Mediterranean-inspired treat.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Roayl Falafel</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Golden-brown falafel, nestled in a fluffy pita with crisp veggies and creamy tahini sauce, offering a satisfying vegetarian option.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Specialmenu