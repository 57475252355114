import React from 'react'
import bg14 from '../../assets/images/background/bg-14.png'
import bg15 from '../../assets/images/background/bg-15.png'
import siconone from '../../assets/images/resource/why-icon-1.png'
import sicontwo from '../../assets/images/resource/why-icon-2.png'
import siconthree from '../../assets/images/resource/why-icon-3.png'
import siconfour from '../../assets/images/resource/why-icon-4.png'
import service from '../../assets/images/resource/tor-ca.jpg'


function Aboutservice() {
    return (
        <>
        
            <section className="services-section">
                {/* <div className="left-bg"><img src={bg14} alt="" title="" /></div>
                <div className="right-bg"><img src={bg15} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>our services</span></div>

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Exquisite Restaurant in City</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="s-col col-lg-4 col-md-6 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="s-block">
                                    <div className="inner">
                                        <div className="icon-box"><img src={siconone} alt="" title="" /></div>
                                        <h4>Hygienic Food</h4>
                                        <div className="text">Rest assured that your well-being is our priority, our commitment to stringent hygiene practices ensures every dish is as safe as it is delicious.</div>
                                    </div>
                                </div>
                                <div className="s-block alternate">
                                    <div className="inner">
                                        <div className="icon-box"><img src={sicontwo} alt="" title="" /></div>
                                        <h4>Fresh Environment</h4>
                                        <div className="text">Immerse yourself in a refreshing atmosphere that complements our delectable dishes, providing the perfect backdrop for memorable moments.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="s-col last col-lg-4 col-md-6 col-sm-12">
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="s-block alternate">
                                    <div className="inner">
                                        <div className="icon-box"><img src={siconthree} alt="" title="" /></div>
                                        <h4>Skilled Chefs</h4>
                                        <div className="text">Indulge in culinary creations crafted by our skilled chefs, whose passion and expertise infuse every bite with exceptional flavors and creativity.</div>
                                    </div>
                                </div>
                                <div className="s-block">
                                    <div className="inner">
                                        <div className="icon-box"><img src={siconfour} alt="" title="" /></div>
                                        <h4>Catering</h4>
                                        <div className="text">Experience the delight of personalized dining with our catering service, tailored to your unique tastes and occasions.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image-col col-lg-4 col-md-12 col-sm-12">
                            <div className="inner wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image-box"><img src={service} alt="" title="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Aboutservice