import React from 'react'
import { Link } from 'react-router-dom'
import menuimg5 from '../../../assets/images/resource/menu-image-5.png'
import softDrinksImg from '../../../assets/images/resource/CocaCola.jpg'
import menuimg6 from '../../../assets/images/resource/menu-image-6.png'
import chickenTikkaRoll from '../../../assets/images/resource/Paneer Tikka Roll.jpg'
import menuimg8 from '../../../assets/images/resource/menu-image-8.png'

import butterChickenRoll from '../../../assets/images/resource/chickenRoll.png'
import butterChickenBowl from '../../../assets/images/resource/Butter Chicken Bowl.jpg'
import lentilSoup from '../../../assets/images/resource/lentil soup.png'
import rice from '../../../assets/images/resource/rice.png'
import frenchFries from '../../../assets/images/resource/French Fries.jpg'
import pitaBread from '../../../assets/images/resource/pita Bread.png'
import pitaChips from '../../../assets/images/resource/pita chips.png'

import butterPannerBowl from '../../../assets/images/resource/Butter Paneer Bowl.jpg'
import juiceImg from '../../../assets/images/resource/MangoJuiceSplash.jpg'
import menuimg9 from '../../../assets/images/resource/menu-image-9.png'
import bg20 from '../../../assets/images/background/bg-20.png'
import bgone from '../../../assets/images/background/bg-16.png'
import saladsMainImg from '../../../assets/images/resource/greekSaladTemplate.jpg'
import bakedWings from '../../../assets/images/resource/16pcChickenwings.jpg'
import bgtwo from '../../../assets/images/background/bg-17.png'
import bgthree from '../../../assets/images/background/bg-18.png'
import SoyaChaap from '../../../assets/images/resource/SoyaChaap.jpg'
import ChocoHazelnutBaklava from '../../../assets/images/resource/ChocoHazelnutBaklava.jpg'
import PaneerTikka from '../../../assets/images/resource/PaneerTikka.jpg'
import garlicPotatoes from '../../../assets/images/resource/garlic potatoes.jpg'


function Listmenu() {
    return (
// <span className="s-info"></span>
        <>
{/* Sides */}
            <section className="menu-two" style={{"marginTop": "-80px"}}>
                {/* <div className="left-bg"><img src={bg20} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Sides</h2>

                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={rice} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Rice</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Fluffy perfection to complement any dish on our menu.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={frenchFries} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">French Fries<span className="s-info">POPULAR</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Golden and crispy satisfaction in every bite.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={garlicPotatoes} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Garlic Potatoes</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Buttery tenderness infused with aromatic garlic.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={pitaBread} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Pita Bread</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Vegetables, cheeses, ground meats, tomato sauce, seasonings and spices.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={pitaChips} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Pita Chips</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Avocados with crab meat, red onion, crab salad stuffed red bell pepper and  green bell pepper.</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={lentilSoup} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Lentil Soup</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Avocados with crab meat, red onion, crab salad stuffed red bell pepper and  green bell pepper.</Link></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
{/* Indian Rolls & Bowls */}
            <section className="menu-two" style={{"marginTop": "-65px"}}>
                {/* <div className="left-bg"><img src={bg20} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Indian Rolls & Bowls</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={PaneerTikka} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Paneer Tikka Bowl <span className="s-info">VEGETARIAN</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Authentic Indian basmati rice topped with Paneer tikka with makhani gravy on it, which comes with salad (red onion and lettuce) and mint sauce.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={butterPannerBowl} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Butter Paneer Bowl<span className="s-info">VEGETARIAN</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Authentic Indian basmati rice topped with Deep Fried Paneer with makhani gravy on it, which comes with salad (red onion and lettuce) and mint sauce.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={chickenTikkaRoll} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Chicken Tikka Roll</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Tikka of Chicken Marinated in yogurt and spices filled in rumali roti with salad (red onion and lettuce) topped with mint sauce.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={butterChickenBowl} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Butter Chicken Bowl</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Tandoori cooked morsels of chicken finished in creamy tomato gravy, which comes with basmati rice, salad (red onion and lettuce) and mint sauce.</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={butterChickenRoll} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Butter Chicken Roll</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Tandoori cooked morsels of chicken finished in creamy tomato gravy filled in rumali roti with salad (red onion and lettuce) and mint sauce on it.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={SoyaChaap} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Soya Chaap Tikka <span className="s-info">VEGETARIAN</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Soya bean chunks cooked in creamy tomato gravy, which comes with basmati rice, salad (red onion and lettuce) and mint sauce.</Link></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
{/* Baked Wings */}
<section className="menu-one alternate" style={{"marginTop": "-50px"}}>
                {/* <div className="left-bg"><img src={bgtwo} alt="" title="" /></div>
                <div className="right-bg-2"><img src={bgthree} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Baked Wings</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={bakedWings} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">8 Pieces </Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">8 Baked Breaded chicken Wings with the choice of sauce Honey Garlic/ Buffalo/ Barbeque/ No Sauce.</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">16 Pieces</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">16 Baked Breaded chicken Wings with the choice of sauce Honey Garlic/ Buffalo/ Barbeque/ No Sauce.</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">24 Pieces </Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">24 Baked Breaded chicken Wings with the choice of sauce Honey Garlic/ Buffalo/ Barbeque/ No Sauce.</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">32 Pieces</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">32 Baked Breaded chicken Wings with the choice of sauce Honey Garlic/ Buffalo/ Barbeque/ No Sauce.</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Dressings</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">On-It : Barbique / Buffalo / Honey Garlic / No Sauce
                                                                                On-Side : Barbique / Buffalo / Honey Garlic / Home Style Garlic</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
{/* Salads */}
<section className="menu-one" style={{"marginTop": "-80px"}}>
                {/* <div className="right-bg"><img src={bgone} alt="" title="" /></div> */}
                <div className="auto-container" >
                    <div className="title-box centered">
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Salads</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image" style={{"scale": "105%"}}><img src={saladsMainImg} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Greek Salad <span className="s-info">POPULAR</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Transport yourself to the Mediterranean with our authentic Greek salad, showcasing the perfect balance of tangy feta cheese, juicy tomatoes, crunchy cucumbers, and briny olives, all dressed in a zesty vinaigrette.</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Fattoush <span className="s-info">POPULAR</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Embark on a flavorful journey with our Fattoush salad, combining crunchy toasted pita chips, crisp vegetables, and vibrant herbs, all tossed in a tangy dressing, creating a harmonious symphony of textures and tastes.</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Tabouleh</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Experience the refreshing essence of our Tabouleh salad, featuring a vibrant blend of bulgur wheat, finely chopped herbs, juicy tomatoes, and zesty lemon dressing, delivering a burst of Mediterranean flavors with each forkful.</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Garden Salad </Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Freshness abounds with our vibrant garden salad, featuring a medley of crisp, seasonal vegetables that will awaken your taste buds with every bite.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
{/* Deserts */}            
            <section className="menu-two" style={{"marginTop": "-65px"}}>
                {/* <div className="left-bg"><img src={bg20} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Deserts</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={ChocoHazelnutBaklava} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Choco Hazelnut Baklava <span className="s-info">VEGETARIAN</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Baklava filled with mixture of chocolate and hazelnuts.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Kesar Rasmalai <span className="s-info">VEGETARIAN</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Bengali delicacy of spongy soft cheese dumplings that are cooked in sugar syrup and then soaked in creamy 
                                        cardamom-saffron milk, absorbing all the lovely flavors. 2 pieces in one plate.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
{/* Drinks */}            
<section className="menu-two" style={{"marginTop": "-65px"}}>
                {/* <div className="left-bg"><img src={bg20} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Drinks</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={softDrinksImg} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Pops / Water</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">  </Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={juiceImg} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Juice</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#"> </Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



{/* Salads /OLD/ */}
{/* <section className="menu-two">
                <div className="left-bg"><img src={bg20} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Salads</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={menuimg5} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Garden Salad</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Freshness abounds with our vibrant garden salad, featuring a medley of crisp, seasonal vegetables that will awaken your taste buds with every bite.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Greek Salad</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Transport yourself to the Mediterranean with our authentic Greek salad, showcasing the perfect balance of tangy feta cheese, juicy tomatoes, 
                                        crunchy cucumbers, and briny olives, all dressed in a zesty vinaigrette.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Fattoush</Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Embark on a flavorful journey with our Fattoush salad, combining crunchy toasted pita chips, crisp vegetables, and vibrant herbs, 
                                        all tossed in a tangy dressing, creating a harmonious symphony of textures and tastes.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link to="#"><img src={menuimg9} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Tabouleh <span className="s-info">POPULAR</span></Link></h5></div><div className="price"><span> </span></div></div>
                                        <div className="text desc"><Link to="#">Experience the refreshing essence of our Tabouleh salad, featuring a vibrant blend of bulgur wheat, finely chopped herbs, juicy tomatoes, and zesty lemon dressing, 
                                        delivering a burst of Mediterranean flavors with each forkful.</Link></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}



{/* Booking OLD */}
{/*Booking and special Events}
            {/* <section className="featured-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">

                                <div className="content">
                                    <div className="content-inner">
                                        <h2>Private Events</h2>
                                        <div className="text">Lorem Ipsum is simply dummy printing and typeset industry lorem Ipsum has been the industrys.</div>
                                        <div className="separator"><span></span></div>
                                        <div className="booking-info">
                                            <div className="bk-title">Booking request</div>
                                            <div className="bk-no"><Link to="tel:+88-123-123456">+88-123-123456</Link></div>
                                        </div>
                                        <div className="link-box">
                                            <Link to="#" className="theme-btn btn-style-one clearfix">
                                                <span className="btn-wrap">
                                                    <span className="text-one">Book Now</span>
                                                    <span className="text-two">Book Now</span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="row clearfix">
                                    <div className="img-col col-lg-6 col-md-6 col-sm-6"><div className="image"><img src={featured8} alt="" /></div></div>
                                    <div className="img-col col-lg-6 col-md-6 col-sm-6"><div className="image"><img src={featured9} alt="" /></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="featured-section alternate">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">

                                <div className="content">
                                    <div className="content-inner">
                                        <h2>Banquet Hall</h2>
                                        <div className="text">Lorem Ipsum is simply dummy printing and typeset industry lorem Ipsum has been the industrys.</div>
                                        <div className="separator"><span></span></div>
                                        <div className="booking-info">
                                            <div className="bk-title">Booking request</div>
                                            <div className="bk-no"><Link to="tel:+88-123-123456">+88-123-123456</Link></div>
                                        </div>

                                        <div className="link-box">
                                            <Link to="#" className="theme-btn btn-style-one clearfix">
                                                <span className="btn-wrap">
                                                    <span className="text-one">Book Now</span>
                                                    <span className="text-two">Book Now</span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="row clearfix">
                                    <div className="img-col col-lg-6 col-md-6 col-sm-6"><div className="image"><img src={featured10} alt="" /></div></div>
                                    <div className="img-col col-lg-6 col-md-6 col-sm-6"><div className="image"><img src={featured11} alt="" /></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </> 
    )
}

export default Listmenu