import React, {useState, useEffect} from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom'
import logo from '../../assets/images/resource/sidebar-logo.png'
import Banner from '../Menhome/Banner'
import Offer from '../Menhome/Offer'
import Story from '../Menhome/Story'
import Specialdish from '../Menhome/Specialdish'
import Menusection from '../Menhome/Menusection'
import Whyus from '../Menhome/Whyus'
import Teem from '../Menhome/Teem'
import Reacntupdate from '../Menhome/Recntupdates'
import Special from '../Menhome/Special'
import Contact from '../Menhome/Contact'
import Videosection from '../Menhome/Videosection'


function Main() {

    const [active, setActive] = useState();
    const [show, setShow] = useState();
    const [menu, setMenu] = useState();
    const [page, setPage] = useState();
    const [drop, setDrop] = useState();

    const [scrolled,setScrolled]=useState(false);
    const [Nav,setNav]=useState(true);
    
    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 1000 ){
          setNav(false)
        }
        else if(offset > 200){
            setNav(true);
            setScrolled(true);
        }
        else{
          setScrolled(false);
          setNav(true);
        }
      }
    
      useEffect(() => {
        window.addEventListener('scroll',handleScroll)
      })

    return (
        <> 

            {active && 
                <div className="menu-backdrop" style={{ opacity: "1"  , visibility: "visible"}} onClick={() => setActive(false)}></div> }
                     <section className={`hidden-bar ${active && "visible-sidebar"}`} onClick={() => setActive(false)} >
                    <div className="inner-box">

                        <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)} ><span className="far fa-close"> </span></div>
                        <div className="logo-box"><Link to="/" title="Oasis Shawarma and Wings"><img src={logo} alt="" title="Oasis Shawarma and Wings" />
                        </Link></div>
                            
                        <h2>Visit Us</h2>
                        <ul className="info">
                            <li>Restaurant St, Delicious City, <br /> London 9578, UK </li>
                            <li>Open: 9.30 am - 2.30pm</li>
                            <li><Link>oasisshawarmaandwingsgmail.com</Link></li>
                        </ul>
                        <div className="separator"><span></span></div>
                        <div className="booking-info">
                            <div className="bk-title">Booking request</div>
                            <div className="bk-no"><Link>+1 647 368 8200</Link></div>
                        </div>

                    </div>
                     </section>

             {Nav &&
                 <header className={`main-header ${scrolled && "fixed-header"} header-down`}>

                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                        <li><i className="icon far fa-map-marker-alt"></i> 3308 LAKE SHORE BLVD W, UNIT 5, ETOBICOKE M8V 1M4, ON</li>
                                        <li><i className="icon far fa-clock"></i> Tuesday - Sunday : 10.30 am to 12.00 pm  -  Monday : Closed</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-upper">        

                        <div className="auto-container">

                            <div className="main-box clearfix">
                                <div className="nav-boxtwo clearfix">
                                    <div className="nav-outertwo clearfix">         
                                        <nav className="main-menu">
                                            
                                            <ul className="navigation clearfix">
                                                    <li className="current"><Link to="/">Home</Link></li>
                                                    <li ><Link to="/menu">Menu</Link></li>
                                                    <li><Link to="/about">About Us</Link></li>
                                                    <li><Link to="/contact">Contact</Link></li>
                                                </ul>

                                        </nav>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                 </header>
                }
            


            <Banner />
            <Offer />
            <Story />
            <Specialdish />
            <Menusection />
            <Special />
            <Contact />
            <Whyus />
            <Teem />
            <Videosection />
            <Reacntupdate />



        </>
           
    )
}

export default Main