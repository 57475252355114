import React from 'react'
import { Link } from 'react-router-dom'
import Slideone from '../../assets/images/main-slider/slider-1.jpg'
import Slidetwo from '../../assets/images/main-slider/slider-1.jpg'
import Slidethree from '../../assets/images/main-slider/slider-1.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import qrCode from '../../assets/images/SVG/QR.svg'


let QrLink = "https://www.clover.com/online-ordering/oasis-shawarma-and-win-toronto?utm_source=web-dashboard&utm_medium=qrcode&utm_campaign=olo-qr-template";

function Banner() {
    return (
        <>

            <Swiper className="banner-section"
                loop={true}
                spaceBetween={50}
                slidesPerView={1} 
                navigation={{
                    nextEl: '.swiper-button-prev',
                    prevEl: '.swiper-button-next',
                  }}>

                <div className="banner-container">
                    <div className="banner-slider" >
                        <div className="swiper-wrapper">

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">

                                    <div className="image-layer" style={{ backgroundImage: `url(${Slideone})` }}></div>

                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>delightful experience</span></div>

                                                        <div className="pattern-image">

                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />

                                                        </div>


                                                        <h1><span>Flavors Inspired by <br />the Seasons</span></h1>
                                                        <div className="text">Come with family & feel the joy of mouthwatering food</div>
                                                        <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                            <div className="link">
                                                                <Link to="/menu" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">view our menu</span>
                                                                        <span className="text-two">view our menu</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>  
                        </div>

                        <div className="swiper-button-prev"><span className="fal fa-angle-left"></span></div>
                        <div className="swiper-button-next"><span className="fal fa-angle-right"></span></div>
       

                    </div>
                </div>

                <div className="book-btn" >
                    <Link to={QrLink} target="_blank" className="theme-btn" >
                        <span className="icon" >
                            <img className="img-qr" src={qrCode} alt="" title="" />
                        </span>
                        <span className="txt">Scan to Order</span>
                    </Link>
                </div>
            </Swiper>

        </>
    )
}

export default Banner