import React from 'react'
import Backgroundimage from '../../assets/images/background/bgReview.jpg';
import OwlCarousel from 'react-owl-carousel';
import quotesone from '../../assets/images/icons/quotes-1.png'
import authorthumbF from '../../assets/images/resource/author-thumb-2.jpg'
import authorthumbM from '../../assets/images/resource/author-thumb-1.jpg'



function Testimonialsabout() {
    const show ={
        loop:true,
			margin:50,
			nav:true,
			smartSpeed: 700,
			autoplay: true,
			autoplayTimeout:7000,
			navText: [ '<span class="icon fa-light fa-angle-left"></span>', '<span class="icon fa-light fa-angle-right"></span>' ],
			responsive:{
				0:{
					items:1
				},
				600:{
					items:1
				},
				768:{
					items:2,
					margin:30
				},
				992:{
					items:2,
					margin:30
				},
				1200:{
					items:3
				}
			}
    };

    return (
        <>
        
            <section className="testimonials-two">
                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}></div>
                <div className="auto-container">

                    <div className="title-box centered">
                        <div className="subtitle"><span>testimonials</span></div>
                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>What People Are Saying</h2>
                    </div>

                    <div className="carousel-box" show={show} >

                        <OwlCarousel className="testimonial-slider owl-theme owl-carousel" loop margin={50} {...show}>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">This place was amazing! Fresh delicious food in generous portions and super friendly service! Definitely will be back!!!</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumbF} alt="" /></div>
                                        <div className="auth-title">Maryann Jones</div>
                                        <div className="location">Toronto</div>
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">The owner is also the chef and the food is fantastic and the service caring. We have enjoyed everything ordered here.</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumbF} alt="" /></div>
                                        <div className="auth-title">Judith Haynes</div>
                                        <div className="location">Toronto</div>
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">MUST TRY! We went here very late and the staff were beyond kind despite being just before they closed. The food was AMAZING! So flavourful. The chicken platter my boyfriend got was HUGE, great value. I'm vegan and the staff were super helpful and no compromises on flavour. 10/10</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumbF} alt="" /></div>
                                        <div className="auth-title">Kelly Drescher</div>
                                        <div className="location">Toronto</div>
                                    </div>
                                </div>
                            </div>

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">Fresh and high quality ingredients are what makes this place stand out from all other shawarma places! Not only that, but the owners are warm hearted, humble people, whose main priority is giving you a positive experience, which is priceless. Will definitely be back and highly recommend!</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumbF} alt="" /></div>
                                        <div className="auth-title">Yuliana Cortes</div>
                                        <div className="location">Toronto</div>
                                    </div>
                                </div>
                            </div>
{/* 
                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">Hygienic food & fresh enviroment, everyone had a wonderful delite experience. It was FABULOUS! great experience at The Italian gourmet.</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumbone} alt="" /></div>
                                        <div className="auth-title">Theresa Tin</div>
                                        <div className="location">Chicago</div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="testi-block item">
                                <div className="inner-box">
                                    <div className="quote-icon"><img src={quotesone} alt="" title="" /></div>
                                    <div className="rating"><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span></div>
                                    <div className="text">The owner is so friendly and kind. The ingredients are fresh and he makes the wrap with lots of meat and sauce. The taste is incredible and this is now my go to place for reasonably priced and great tasting shawarma!</div>
                                    <div className="auth-info">
                                        <div className="image"><img src={authorthumbF} alt="" /></div>
                                        <div className="auth-title">Hari Krishnan</div>
                                        <div className="location">Toronto</div>
                                    </div>
                                </div>
                            </div>

                        </OwlCarousel>
                        
                    </div>

                </div>
            </section>

        </>
    )
}

export default Testimonialsabout