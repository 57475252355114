import React from 'react'
import { Link } from 'react-router-dom'
import bg1 from '../../assets/images/background/bg-1.png'
import bg2 from '../../assets/images/background/bg-2.png'
import ChickenShawarmaPlatter from '../../assets/images/resource/ChickenShawarmaPlatter.jpg'
import FalafelApt from '../../assets/images/resource/royalFalafel.jpg'
import MangoJuiceSplash from '../../assets/images/resource/MangoJuiceSplash.jpg'



function Offer() {
    return (
        <>
            <section className="we-offer-section">
            {/* Decals
                <div className="left-bot-bg"><img src={bg1} alt="" title="" /></div>
                <div className="right-top-bg"><img src={bg2} alt="" title="" /></div>
            */}

                <div className="auto-container">

                    <div className="title-box centered">

                        <div className="subtitle"><span>Flavors for royalty</span></div>

                        <div className="pattern-image">

                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>We Offer Top Notch</h2>
                        <div className="text">
                        Our ingredients are carefully selected to ensure that they are of the highest quality. we use fresh ingredients to create dishes that are bursting with flavor
                        We believe that cooking is an art form. Our chefs are passionate about their craft, and they take great care in preparing each dish to perfection. Whether you're in the mood for a juicy Chicken, a fresh salad, or a decadent dessert, we have something for everyone.
                        </div>

                    </div>

                    <div className="row justify-content-center clearfix">

                        <div className="offer-block col-xl-4 col-lg-4 col-md-6 col-sm-12">

                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image"><Link to="/menu"><img src={ChickenShawarmaPlatter} alt="" /></Link></div>
                                <h3><Link to="/menu">Platters</Link></h3>
                                <div className="more-link"><Link to="/menu">view menu</Link></div>
                            </div>

                        </div>


                        <div className="offer-block col-xl-4 col-lg-4 col-md-6 col-sm-12">

                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                <div className="image"><Link to="/menu"><img src={FalafelApt} alt="" /></Link></div>
                                <h3><Link to="/menu">Appetizers</Link></h3>
                                <div className="more-link"><Link to="/menu">view menu</Link></div>
                            </div>

                        </div>


                        <div className="offer-block col-xl-4 col-lg-4 col-md-6 col-sm-12">

                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div className="image"><Link to="/menu"><img src={MangoJuiceSplash} alt="" /></Link></div>
                                <h3><Link to="/menu">Drinks</Link></h3>
                                <div className="more-link"><Link to="/menu">view menu</Link></div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>
        </>
    )
}

export default Offer