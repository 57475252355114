import React from 'react'
import Startermenu from '../Allmenus/FirstMenu/Startermenu';
import Bredcom from '../Bredcrumb/Main'
import Specialmenu from '../Allmenus/Menmenu/Specialmenu';
import Listmenu from '../Allmenus/Menmenu/Listmenu';
import Img from '../../assets/images/resource/menu-hero.jpg'


function Main() {
  return (
    <>
      <Bredcom
        subtitle="Our Menu"
        title="DELICIOUS & AMAZING"
        Img={Img}
      />

      <Startermenu />
      <Specialmenu />
      <Listmenu />
      
    </>
  )
}

export default Main